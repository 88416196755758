<template>
  <div>
    <Breadcrumbs page_title="sound" :items="breadcrumbs" />
    <v-card class="mx-3 my-5 rounded-lg">
      <template>
        <v-container fluid>
          <v-row class="mt-0" v-if="hasData">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="3"
              v-for="(item, i) in items"
              :key="i"
              class="pl-4 pr-4 pt-0"
            >
              <v-card
                style="
                  border-radius: 16px;
                  background-color: #f0f0f0;
                  box-shadow: unset;
                "
                height="auto"
              >
                <v-card-text
                  justify="center"
                  align="center"
                  style="
                    height: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 10px;
                    padding-bottom: 5px;
                  "
                >
                  <p
                    class="text-center mb-2"
                    style="font-size: 18px; color: #424242"
                  >
                    {{ item.contentName }}
                  </p>
                  <v-img :src="item.optimizeLink" class="soundimg"></v-img>
                  <div
                    style="height: 135px; overflow-x: hidden; overflow-y: auto"
                    class="overflow-y-auto mt-4"
                  >
                    <v-row
                      v-for="(item1, i) in item.videoList"
                      :key="i"
                      style="cursor: pointer"
                    >
                      <v-col
                        cols="12"
                        sm="6"
                        xs="6"
                        md="9"
                        lg="9"
                        class="pl-1 pr-2 pt-2 pb-1"
                        style="text-align: left"
                      >
                        <div
                          class="contentTextStyle"
                          @click="
                            PlayMP3(item1, item, item.viewerFlag),
                              prepareSongList(
                                item1.content_ID,
                                item.videoList,
                                item.lesson_id_no,
                                item.viewerFlag
                              ),
                              $refs.soundPlay.clickPlayBtn(true)
                          "
                        >
                          <span style="font-size: 14px">{{
                            item1.content_Name
                          }}</span>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        class="pl-2 pr-3 pt-2 pb-2"
                        style="text-align: end"
                      >
                        <v-progress-circular
                          :rotate="-90"
                          :size="30"
                          :width="3"
                          :value="item1.progressTime * 100"
                          color="#4FB14E"
                          class="mx-auto my-auto center playIcon"
                          @click="
                            PlayMP3(item1, item, item.viewerFlag),
                              prepareSongList(
                                item1.content_ID,
                                item.videoList,
                                item.lesson_id_no,
                                item.viewerFlag
                              ),
                              $refs.soundPlay.clickPlayBtn(true)
                          "
                        >
                          <v-icon
                            style="
                              font-size: 20px;
                              color: #cccccc;
                              padding-left: 3px;
                              padding-top: 2px;
                              margin-bottom: 3px;
                            "
                            >mdi-play-outline</v-icon
                          >
                        </v-progress-circular>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="d-flex justify-end align-center pt-3 pr-2 pb-1"
                    >
                      {{ $t("enddate") + " : " + item.endDateOfContent }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-0" v-else-if="loading">
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="4"
              lg="3"
              class="pl-4 pr-4"
              v-for="n in 3"
              :key="n"
            >
              <v-skeleton-loader
                class="mx-auto"
                style="height: 370px; border: solid 1px #f0f0f0; padding: 10px"
                type="text,image,list-item-two-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col>
              <div class="text-center nodata">{{ $t("nosound") }}</div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>
    <soundPlay
      ref="soundPlay"
      :show="showSound"
      @close="(showSound = false), GetSoundData()"
      @show_sound="show_sound"
      @updateProgressCircle="updateProgressCircle"
      @skipToNext="playNextSong"
      @skipToPrevious="playPreviousSong"
      :audioName="audioName"
      :audioDescription="audioDescription"
      :dialog="dialog"
      :viewerFlag="viewerFlag"
      :audioFile="contentFile"
      :audioImg="audioImg"
      :contentid="contentId"
      :currentminutes="currentminutes"
      :playListOpt="songList"
      :memberID="memberID"
    ></soundPlay>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import axios from "axios";
import soundPlay from "./soundPlay.vue";

export default {
  components: {
    soundPlay,
  },
  data() {
    return {
      viewerFlag: false,
      memberID: "",
      songList: {
        previous: false,
        next: false,
        lessonId: 0,
        rewind: false,
      },
      power: 0,
      startLoadingAudio: false,
      currentAudioTime: "0:00",
      audioDuration: "0:00",
      showPlay: true,
      showMp3Player: false,
      audioDescription: "",
      audioName: "",
      audioImg: "",
      //audioUrl: require("./Saung.mp3"),
      wavesurfer: null,
      wavesurferOptions: {
        container: "#waveform",
        waveColor: "#eee", //#D2EDD4
        progressColor: "#A6CC39", //#46B54D
        cursorColor: "#424242",
        barHeight: 1,
        barRadius: 3,
        barWidth: 3,
        height: 95,
        responsive: true,
        origin: "*",
        backend: "MediaElement",
      },
      //////
      userID: "",
      companyID: "",
      breadcrumbs: [
        {
          text: "library",
          href: "/library",
        },
        {
          text: "sound",
        },
      ],
      items: [],
      loading: true,
      Addfav: false,
      contentId: 0,
      hasData: false,
      currentminutes: "",

      // AddReport
      progress_time: 0,
      actualViewTime: 0,
      intervalId: 0,
      complete_dialog: false,
      confirmDialog: false,
      count: 0,
      exit_dialog: false,
      content_ID: 0,
      nextSound: [],

      //Sound Data
      showSound: false,
      dialog: false,
      contentFile: "",
      favFlag: "",
      // soundPlay: [],
      activeItem: null,
    };
  },
  computed: {
    heartIcon() {
      return this.Addfav === true ? "mdi-heart" : "mdi-heart-outline";
    },
  },
  mounted() {
    this.GetSoundData();
    this.getNotificationCount();
    this.getFavourite();
  },
  methods: {
    updateProgressCircle(p) {
      this.items = this.items.map((x) => ({
        ...x,
        progressTime: x.id == p.id ? p.progressTime : x.progressTime,
      }));
    },
    playNextSong(nextValue) {
      console.log("rewind", nextValue);
      let temp = null;
      let tempVList = null;
      this.items.forEach((element) => {
        if (element.lesson_id_no === nextValue.lessonId) {
          (tempVList = element.videoList),
            (temp = element.videoList.find(
              (x) => x.content_ID === nextValue.next
            ));
        }
      });
      this.prepareSongList(temp.content_ID, tempVList, nextValue.lessonId, nextValue.rewind);
      this.PlayMP3(temp, this.items, nextValue.rewind);
    },
    playPreviousSong(preValue) {
      let temp = null;
      let tempVList = null;
      this.items.forEach((element) => {
        if (element.lesson_id_no === preValue.lessonId) {
          (tempVList = element.videoList),
            (temp = element.videoList.find(
              (x) => x.content_ID === preValue.previous
            ));
        }
      });
      this.prepareSongList(temp.content_ID, tempVList, preValue.lessonId, preValue.rewind);
      this.PlayMP3(temp, this.items, preValue.rewind);
    },
    prepareSongList(currentSongId, v_item, title_id, rewindFlag) {
      let self = this;
      let indexOfcurrentSong = v_item.indexOf(
        v_item.find((x) => x.content_ID === currentSongId)
      );

      let nextIndex = v_item[indexOfcurrentSong + 1]?.content_ID
        ? v_item[indexOfcurrentSong + 1]?.content_ID
        : null;
      let previousIndex = v_item[indexOfcurrentSong - 1]?.content_ID
        ? v_item[indexOfcurrentSong - 1]?.content_ID
        : null;
      if (v_item.length > 1) {
        //loop the song list
        self.songList = {
          next: nextIndex ? nextIndex : v_item[0]?.content_ID,
          previous: previousIndex
            ? previousIndex
            : v_item[v_item.length - 1]?.content_ID,
          lessonId: title_id,
          rewind: rewindFlag
        };
      } else {
        self.songList = {
          next: nextIndex ? nextIndex : null,
          previous: previousIndex ? previousIndex : null,
          lessonId: title_id,
          rewind: rewindFlag
        };
      }
    },
    show_sound() {
      this.showSound = true;
    },
    PlayMP3(item, mainItem, viewerFlag) {
      console.log("main-item = ", mainItem);
      this.viewerFlag = viewerFlag, // mainItem.viewerFlag;
      this.activeItem = item.id;
      this.showSound = true;
      this.audioImg = item.optimizeLink;
      this.audioName = item.content_Name;
      this.audioDescription = item.wordDescription;
      this.contentId = item.content_ID;
      this.$refs.soundPlay.getFavorite(item.favFlag);
      this.$refs.soundPlay.GetCommentsForSound(item.content_ID);
      this.memberID = item.memberID;
      this.contentFile = item.content_File;
      if (item.progressTime == "1") {
        this.currentminutes = 0;
      } else {
        this.currentminutes = item.currentminutes;
      }
    },
    formatTimeNew(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    convertToSeconds(timeString) {
      const [minutes, seconds] = timeString.split(":").map(parseFloat);
      return minutes * 60 + seconds;
    },
    // playAudio() {
    //   this.wavesurfer.play();
    //   this.showPlay = false;
    // },

    async pauseAudio() {
      this.wavesurfer.pause();
      this.showPlay = true;
    },

    async GetSoundData() {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        topicID: parseInt(localStorage.getItem("libraryId")),
      };
      const res = await this.$axios.post(
        `${this.web_urlV5}Video/GetSoundData`,
        request
      );      
      if (res.data.data.length > 0) {
        this.items = res.data.data.map((v, i) => ({
          ...v,
          lesson_id_no: i + 1,
          endDateOfContent: moment(v.endContentDate)
            .local()
            .format("DD MMM YYYY"),
        }));

        this.loading = false;
        this.hasData = true;
      } else {
        this.loading = false;
        this.hasData = false;
      }
    },
    toggleCheck() {
      this.Addfav = !this.Addfav;
      this.updateFavourite(this.Addfav);
    },
    updateFavourite(isFavourite) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        contentID: this.contentId,
        contentCheck: 2,
        favFlag: isFavourite,
      };
      axios
        .post(`${this.web_url}Contents/UpdateFavorite`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            if (isFavourite === true) {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount + 1
              );
            } else {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount - 1
              );
            }
          }
        });
    },
    viewComplete() {
      // Stop the view time tracking timer
      let self = this;
      clearInterval(self.viewTimeInterval);
      let actualview_time = self.actualViewTime.toString();
      const [minutes, seconds] = self.currentAudioTime
        .split(":")
        .map(parseFloat);
      const minute_data = minutes * 60 + seconds;
      // Save the user activity report with API
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        id: self.content_ID,
        minutes: minute_data.toString(),
        separatePage: 4,
        progressTime: "1",
        actualViewTime: actualview_time,
      };

      axios
        .post(`${self.web_url}Reports/AddReportActivity`, request)
        .then(function (res) {
          if (res.data.status === 0) {
            self.complete_dialog = false;
            self.actualViewTime = 0;
            self.count = 0;
            self.exit_dialog = false;
          } else {
            console.error("Failed to save user activity report");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancelExitPlayer() {
      this.exit_dialog = false;
      this.wavesurfer.play();
      this.showPlay = false;
    },
  },
};
</script>

<style scoped>
.soundimg {
  height: 180px;
  object-fit: cover;
  width: 280px;
  border-radius: 16px;
}
.lessonIcon {
  top: 15px;
  position: absolute;
}
.test {
  display: flex;
  justify-content: center;
}
.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.Caseicon {
  left: 12px;
  color: #ff6060 !important;
  font-size: 38px !important;
  top: 12px;
}
::v-deep .audioPhoto {
  object-fit: cover;
  /* width: 280px; */
  border-radius: 16px;
}
/* disable shadow color on v-icon */
.v-icon.v-icon::after {
  transform: scale(0);
}

/* Heart icon */
.heart-icon:hover {
  cursor: pointer;
}
.heart-icon {
  font-size: 45px;
  width: 35px;
}
.noarticle {
  height: 200px;
  align-items: center;
}
.nodata {
  color: #a4a4a4;
  font-size: 20px !important;
}
.loadingarticle {
  height: 250px;
}

.contentTextStyle {
  width: 160px;
  height: 25px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  padding-top: 4px;
}
.playIcon {
  margin-right: 0.4rem !important;
}

::v-deep .overflow-y-auto::-webkit-scrollbar {
  width: 3px !important;
  border-radius: 10px !important;
}
/* Track */
::v-deep .overflow-y-auto::-webkit-scrollbar-track {
  width: 3px !important;
  background: #c0c0c05c;
  border-radius: 10px !important;
}
/* Handle */
::v-deep .overflow-y-auto::-webkit-scrollbar-thumb {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: transparent;
  border-top: 40px solid #424242;
  /* border-bottom: 30px solid #424242; */
}
::v-deep .overflow-y-auto {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 8px;
  padding-bottom: 0px;
}
/* For scroll bar in dialog -- hide */
::v-deep .v-dialog--scrollable::-webkit-scrollbar {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep .v-dialog--scrollable::-webkit-scrollbar-track {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep .v-dialog--scrollable::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 1px #e6e6e6;
}
</style>

<style scoped>
@media (min-width: 1441px) and (max-width: 2560px) {
  .soundimg {
    height: 160px;
    object-fit: cover;
    width: unset;
    border-radius: 8px;
  }
}
@media (min-width: 1535px) and (max-width: 1836px) {
  .contentTextStyle {
    width: 200px;
    height: 25px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    padding-top: 4px;
    padding-left: 10px;
  }
}
@media (min-width: 1836px) and (max-width: 2243px) {
  .contentTextStyle {
    width: 275px;
    height: 25px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    padding-top: 4px;
    padding-left: 10px;
  }
}
@media (min-width: 2243px) and (max-width: 2560px) {
  .contentTextStyle {
    width: 370px;
    height: 25px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    padding-top: 4px;
    padding-left: 10px;
  }
}
</style>
